/* Site CSS */
.content { background: $light; }
.newsletters {
    input, button {
        padding: 1rem;
    }
}
.banner, .header {
    background: $dark;
    color: $light;
}
.header {
    .breadcrumb {
        .breadcrumb-item {
            a {
                color: $white;
                text-decoration: none;
            }
            &.active {
                color: $light;
            }
        }
    }
}
h2.title {
    color: $primary;
    position: relative;
    display: inline-block;
    margin-bottom: $h2-font-size;
    &:after{
        content: "";
        position: absolute;
        width: 50%;
        left: 25%;
        bottom: -0.75rem;
        border-bottom: 2px solid $primary;
    }
}
#map {
    height: 900px;
    &.narrow {
        height: 450px;
    }
}
.alert {
    border: none;
    color: $white;
    a, .alert-link {
        color: $white;
        text-decoration: none;
    }
}
img.img-grayscale {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.invert {
    filter: invert(100%);
}
.popover-tour{
    .popover-body {
        padding: 1rem;
        font-size: $font-size-base * 0.9;
    }
    .popover-footer {
        .btn {
            font-size: $font-size-base * 0.9;
            background: $dark;
            color: $white;
        }
    }
}
#demo { padding: 1rem; }
.carousel-indicators [data-bs-target] {
    background-color: $dark;
}
.carousel-caption {
    background: rgba(0,0,0, 0.25);
}
.sidebar { background: $white; }
.terms {
    height: 200px;
}
