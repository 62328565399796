$font-family-sans-serif: 'Mulish', sans-serif;
$font-size-base: 1rem;
$font-weight-normal: 300;

$primary: #c61a0f;
$secondary: #f9de59;
$dark: #0d0d0d;
$light: #fafafa;

$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;

$badge-font-size: .80em;
$badge-font-weight: 300;

$headings-font-family: 'Work Sans', sans-serif;
$headings-font-weight: 300;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$alert-bg-scale: 0;
$alert-border-scale: 0;
$alert-color-scale: 0;
$alert-padding-y: 1.5rem;
$alert-padding-x: 3rem;
