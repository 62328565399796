// Fonts
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&family=Work+Sans:wght@500&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Bootstrap Icons
@import '~bootstrap-icons/font/bootstrap-icons';

// Animate.css
@import "~animate.css/animate";

// Site CSS
@import 'site';
